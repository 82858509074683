<template>
  <ion-page>
    <Header title="Kupon Undian" />
    <ion-content :fullscreen="true">
      <div class="container" v-if="coupon">
        <Coupon :coupon="coupon" :status="false" />

        <div class="a1s text-sm mt-2">Kode kupon</div>
        <div class="code bold mb-2">
          {{ coupon.coupon_number }}
        </div>
        <div class="divinder"></div>
        <div class="s12sh mt-2">
          <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.6667 25V45.8333H8.33337V25" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M45.8333 14.5834H4.16663V25H45.8333V14.5834Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25 45.8334V14.5834" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25 14.5833H15.625C14.2436 14.5833 12.9189 14.0346 11.9421 13.0578C10.9654 12.0811 10.4166 10.7563 10.4166 9.37496C10.4166 7.99362 10.9654 6.66886 11.9421 5.69211C12.9189 4.71536 14.2436 4.16663 15.625 4.16663C22.9166 4.16663 25 14.5833 25 14.5833Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25 14.5833H34.375C35.7563 14.5833 37.0811 14.0346 38.0579 13.0578C39.0346 12.0811 39.5833 10.7563 39.5833 9.37496C39.5833 7.99362 39.0346 6.66886 38.0579 5.69211C37.0811 4.71536 35.7563 4.16663 34.375 4.16663C27.0833 4.16663 25 14.5833 25 14.5833Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div class="sx mt-2" v-if="! coupon.event.winner">
            <div class="text-sm">Menunggu Undian</div>
            <div class="text-red bold">{{ $filters.dateFormat(coupon.event.finish_at, 'full') }}</div>
            <div class="text-sm">Pantau terus, jangan sampai ketinggalan!</div>
          </div>

          <div class="sx mt-2" v-if="coupon.event.winner">
            <div class="text-sm">Pemenang Undian</div>
            <div class="bold">{{ coupon.event.winner.coupon_number }}</div>
            <!-- <div class="text-sm">{{ coupon.event.winner.user.name }}</div> -->
            <div class="text-sm">{{ $filters.maskEmail(coupon.event.winner.user.email) }}</div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import Coupon from "@/components/coupon";
import Header from '@/components/headerPage'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    Header,
    Coupon
  },
  data () {
    return {
      coupon: null
    }
  },
  created() {
    this.getCoupon()
  },
  watch: {
    '$route': 'getCoupon'
  },
  methods: {
    async getCoupon () {
      let id = this.$route.params.id
      if(id) {
        this.coupon = await this.$api.get(`/api/coupon/${id}`).then(res => res.data)
      }
    }
  }
})
</script>

<style lang="scss">
</style>
